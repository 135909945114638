import { ChangeKeyDataChargeInfoEntity } from "domain/entity/ChangeKeyData/ChangeKeyDataChargeInfoEntity";
import { ChangeKeyDataEntity, EMPTY_CHANGE_KEY_DATA_ENTITY } from "domain/entity/ChangeKeyData/ChangeKeyDataEntity";
import { ChangeKeyDataSearchCriteria, EMPTY_CHANGE_KEY_DATA_SEARCH_CRITERIA } from "domain/entity/ChangeKeyData/ChangeKeyDataSearchCriteria";
import { ChangeKeyDataEnabledSearchCriteria, DEFAULT_ENABLED_CHANGE_KEY_DATA_SEARCH_CRITERIA } from "presentation/constant/ChangeKeyData/ChangeKeyDataEnabledSearchCriteria";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";


export interface ChangeKeyDataDropdownOptions {
    handlingStatusDropdownOptions: DropdownProps[],
    cokdTypeDropdownOptions: DropdownProps[],
    cokdSubTypeDropdownOptions: DropdownProps[],
}
export interface ChangeKeyDataViewChangeState extends BaseViewChangeSate {
    currentSelectedData: ChangeKeyDataEntity
}

export interface ChangeKeyDataModel {
    isLoading: boolean,
    searchCriteria: ChangeKeyDataSearchCriteria,
    dynamicOptions: ChangeKeyDataDropdownOptions,
    changeKeyDatas: ChangeKeyDataEntity[],
    updateHandlingStatus: string,
    changeKeyDataChargeInfos: ChangeKeyDataChargeInfoEntity[],
    selectChargeInfos: ChangeKeyDataChargeInfoEntity[],
    changeKeyDataState: ChangeKeyDataViewChangeState,
    selectedChangeKeyDatas: ChangeKeyDataEntity[],
    allFormState?: { [x: string]: string },
    enabledSearchCriteria: ChangeKeyDataEnabledSearchCriteria,
    isShowCriteriaPanel: boolean,
    isShowEditPanel: boolean,
    isShowChargeInfoPanel: boolean,
    isDoubleClick: boolean,
    isShowLegend: boolean

}

export const EMPTY_CHANGE_KEY_DATA_MODEL: ChangeKeyDataModel = {
    isLoading: false,
    searchCriteria: { ...EMPTY_CHANGE_KEY_DATA_SEARCH_CRITERIA },
    selectedChangeKeyDatas: [],
    allFormState: {},
    enabledSearchCriteria: { ...DEFAULT_ENABLED_CHANGE_KEY_DATA_SEARCH_CRITERIA },
    isShowCriteriaPanel: false,
    isShowEditPanel: false,
    isShowChargeInfoPanel: false,
    isDoubleClick: false,
    isShowLegend: false,
    dynamicOptions: {
        handlingStatusDropdownOptions: [],
        cokdTypeDropdownOptions: [],
        cokdSubTypeDropdownOptions: [],
    },
    changeKeyDatas: [],
    changeKeyDataChargeInfos: [],
    selectChargeInfos: [],
    updateHandlingStatus: "",
    changeKeyDataState: {
        currentSelectedData: EMPTY_CHANGE_KEY_DATA_ENTITY,
        isSliderOpen: false, // true - if stack form open
        isTabularDataActive: true, // false - If list view is active
        isAdd: false, // true - if use add new record
        isRead: false, // true - if user click to view record
        isEditable: false, // true - if user edit existing record
        isSaveClicked: false, // true - if user clicked on save button to save record
        lastEditRowId: "",
        isSelected: false,
        allFormState: {},

        slider: {
            isAdd: false, // true - if use add new record
            isRead: false, // true - if user click to view record
            isEditable: false, // true - if user edit existing record
        }
    },
}